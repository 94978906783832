import makeQueries from '@/@core/helpers/make-queries';
import { unref } from '@vue/composition-api';

export const useIsVerifiedFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);

  let isVerifiedParam = '';

  const showAll = unreffedFilter.isVerified == 'Semua';
  const showVerified = unreffedFilter.isVerified == 'Sudah';
  const showUnverified = unreffedFilter.isVerified == 'Belum';

  if (showVerified) isVerifiedParam = 'isVerified=true';
  if (showUnverified) isVerifiedParam = 'isVerified=false';
  if (showAll) isVerifiedParam = '';

  return isVerifiedParam;
};

export const useIsFormalFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);

  let isFormalParam = '';

  const showAll = unreffedFilter.isFormal == 'Semua';
  const showFormal = unreffedFilter.isFormal == 'Formal';
  const showNonFormal = unreffedFilter.isFormal == 'Non Formal';

  if (showFormal) isFormalParam = 'isFormal=true';
  if (showNonFormal) isFormalParam = 'isFormal=false';
  if (showAll) isFormalParam = '';

  return isFormalParam;
};

export const useDistrictFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const hasDistrict = unreffedFilter.district;

  let districtParam = '';

  const showAll = unreffedFilter.district == 'Semua';

  if (showAll || !hasDistrict) districtParam = '';
  else districtParam = `district=${unreffedFilter.district}`;

  return districtParam;
};

export const useVillageFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const hasVillage = unreffedFilter.village;

  let villageParam = '';

  const showAll = unreffedFilter.village == 'Semua';

  if (showAll || !hasVillage) villageParam = '';
  else villageParam = `village=${unreffedFilter.village}`;

  return villageParam;
};

export const useClassificationFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const { kbli } = unreffedFilter;

  let classificationParam = '';

  if (!kbli || String(kbli).toLowerCase() == 'semua')
    return classificationParam;

  classificationParam = `classification=${
    String(kbli).split(' ')[0]
  }`;

  return classificationParam;
};

export const useSurveyMonthFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const { surveyMonth } = unreffedFilter;

  let param = '';
  if (surveyMonth) param = `surveyMonth=${surveyMonth}`;

  return param;
};

export const useSurveyYearFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const { surveyYear } = unreffedFilter;

  let param = '';
  if (surveyYear) param = `surveyYear=${surveyYear}`;

  return param;
};

export const useScaleFilterToQuery = (filter: any) => {
  const unreffedFilter = unref(filter);
  const { scale } = unreffedFilter;

  let param = '';
  if (scale) param = `scale=${scale}`;

  return param;
};

export const useIndustryFilterToQuery = (
  filter?: any,
  prefix: string = '&'
) => {
  let queries = '';

  if (!unref(filter)) return queries;

  const isVerifiedFilter = useIsVerifiedFilterToQuery(filter);
  const isFormalFilter = useIsFormalFilterToQuery(filter);
  const districtFilter = useDistrictFilterToQuery(filter);
  const villageFilter = useVillageFilterToQuery(filter);
  const classificationFilter = useClassificationFilterToQuery(filter);
  const surveyMonth = useSurveyMonthFilterToQuery(filter);
  const surveyYear = useSurveyYearFilterToQuery(filter);
  const scale = useScaleFilterToQuery(filter);

  if (isVerifiedFilter)
    queries = makeQueries(queries, isVerifiedFilter);
  if (isFormalFilter) queries = makeQueries(queries, isFormalFilter);
  if (districtFilter) queries = makeQueries(queries, districtFilter);
  if (villageFilter) queries = makeQueries(queries, villageFilter);
  if (classificationFilter)
    queries = makeQueries(queries, classificationFilter);
  if (surveyMonth) queries = makeQueries(queries, surveyMonth);
  if (surveyYear) queries = makeQueries(queries, surveyYear);
  if (scale) queries = makeQueries(queries, scale);

  return queries ? prefix + queries : '';
};
