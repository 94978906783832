import { unref } from "@vue/composition-api";
import { useIndustryStore } from "../services/store";

const store = useIndustryStore()

export default async function useInitIndustryForm(id: any) {
  const response: any = await store.getOneIndustry(id);

  if (response) return response.industry
  return null
}

export {
    useInitIndustryForm
}
