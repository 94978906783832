











import { isMobile } from '@/@core/helpers/useBreakpoint'
import {useUiStateStore} from '@/@core/services/uiStateStore'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'KHamburgerMenu',
    props: {
        hideOnMobile: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const uiState = useUiStateStore()
        const showButton = computed(() => !(props.hideOnMobile && isMobile.value))
        
        return {
            uiState,
            showButton
        }
    }
})
