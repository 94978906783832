import { currencyToNumber } from '@/@core/helpers/formatter';
import { computed } from '@vue/composition-api';

export default function useCalculateTotalInvestmentValue(
  investmentValues: any
) {
  return computed(() => {
    let total: number = 0;
    if (!investmentValues?.length) return total;

    investmentValues.map((investment: any, index: number) => {
      const isMultiple = !!investment.data;
      if (!isMultiple)
        total += currencyToNumber(investment.acquisitionValue);
      else
        (investment.data as any[]).map(
          (d) => (total += currencyToNumber(d.acquisitionValue))
        );
    });

    return total;
  });
}

export { useCalculateTotalInvestmentValue };
