import { MaybeRef } from "@vueuse/core"
import { useOptionsParser } from "./options-parser"
import { useSearchParser } from "./search-parser"
import { TableOptions } from "./table-options"

type QueryBuilderOptions = {
    options: MaybeRef<TableOptions> | undefined
    search: MaybeRef<string> | undefined
    relations: string[]
}

export default function useQueryBuilder(queryBuilderOptions: QueryBuilderOptions) {
    const {
        options,
        search,
        relations = []
    } = queryBuilderOptions

    const optionsQuery = useOptionsParser(options)
    const searchQuery = useSearchParser(search, '&')
    const relationsQuery = `&relations=${relations.join(',')}`

    const query = `${optionsQuery}${relationsQuery}${searchQuery}`

    return query
}

export {
    useQueryBuilder
}
