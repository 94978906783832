






import router from '@/router';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'KBreadcrumbs',
  setup(props, ctx) {
    const items = computed(() => {
      // @ts-ignore
      const route: any = router.app._route;
      let pathArray = route.path.split('/');

      pathArray.shift();

      let breadcrumbs = pathArray.reduce(
        (breadcrumbArray: any[], path: string, idx: number) => {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? breadcrumbArray[idx - 1].to + '/' + path
              : '/' + path,
            text: route.matched[idx]?.meta.breadcrumb || path,
            exact: true
          });

          return breadcrumbArray;
        },
        []
      );

      return breadcrumbs;
    });

    return {
      items
    };
  }
});
