import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { useCallApi } from '@/@core/services/axiosCall';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useFinanceStore = defineStore('finance', () => {
  const state = reactive({
    total: 0,
    finances: []
  });

  async function getManyFinance() {
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: 'finances'
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    state.finances = result.finances;
    state.total = result.count;

    return result;
  }

  async function postFinance(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: 'finances',
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function putFinance(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: 'finances/' + data.id,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function deleteFinance(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: 'finances/' + data.id
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }
  return {
    ...toRefs(state),
    getManyFinance,
    postFinance,
    deleteFinance,
    putFinance
  };
});
