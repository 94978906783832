






import router from '@/router'
import { defineComponent, ref, Ref, watch } from '@vue/composition-api'
import { useSwipe } from '@vueuse/core'
import { useGoBack } from "@/@core/helpers/useGoBack"

export default defineComponent({
    name: 'KPage',
    setup() {
        const useNudgeLeft: Ref<boolean> = ref(false)

        // onMounted(() => useNudgeLeft.value = false)
        // onUnmounted(() => useNudgeLeft.value = true)

        const el = ref(null)
        const { isSwiping, direction } = useSwipe(el)

        watch(isSwiping, (val) => {
            // if (direction.value == 'LEFT' && !val) useGoBack(router)
        })

        return {
            useNudgeLeft,
            router,
            isSwiping,
            direction,
            el
        }
    }
})
