import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { currencyToNumber } from '@/@core/helpers/formatter';
import { toFormData } from '@/@core/helpers/formData';
import useQueryBuilder from '@/@core/helpers/query-builder';
import useTableOptions, {
  TableOptions
} from '@/@core/helpers/table-options';
import { useCallApi } from '@/@core/services/axiosCall';
import { useAuthStore } from '@/modules/auth/services/store';
import { useClassificationStore } from '@/modules/classification/services/store';
import { useFinanceStore } from '@/modules/finance/services/store';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useIndustryFilterToQuery } from '../helpers/industryFilterToQuery';
import { useCalculateTotalInvestmentValue } from './calculateTotalInvestmentValue';

export type GetManyQueries = {
  filter?: any;
  options?: MaybeRef<TableOptions>;
  search?: MaybeRef<string>;
};

const classificationKey = 'classification';
const financeKey = 'finance';

const getClassification = async (data: any) => {
  const classificationText =
    data.classification?.fulltext ?? data.classification ?? '';
  const [classificationCode] = classificationText.split(' - ');
  await useClassificationStore().getManyClassification(
    useTableOptions(),
    classificationCode
  );
  const classifications = useClassificationStore()
    .classifications as unknown as any[];

  const classification = classifications.find(
    (c: any) => c.fulltext == data[classificationKey]
  );

  return classification;
};

const getFinance = async (data: any) => {
  await useFinanceStore().getManyFinance();
  const finances = useFinanceStore().finances as unknown as any[];
  const finance = finances.find(
    (c: any) => c.name == data[financeKey]
  );

  return finance;
};

const useIndustryDto = async (data: any) => {
  const classification = await getClassification(data);
  data.classification = classification;

  const finance = await getFinance(data);
  data.finance = finance;

  const hasInvestmentValues = !!data.investmentValues;
  if (hasInvestmentValues) {
    data.investmentValues.map((v: any) => {
      v.pricePerUnit = currencyToNumber(v.pricePerUnit);
      v.acquisitionValue = currencyToNumber(v.acquisitionValue);
    });
  }

  const calculateTotalInvestmentValue =
    useCalculateTotalInvestmentValue(data.investmentValues);
  data.totalInvestmentValue = calculateTotalInvestmentValue.value;

  data.surveyBy = (useAuthStore()?.user as any).id;

  return data;
};

export const useIndustryStore = defineStore('industry', () => {
  const state = reactive({
    total: 0,
    industries: [],
    industry: null,
    statistics: {},
    geolocations: []
  });

  const industryRelations = [
    'products',
    'products.photos',
    'permissions',
    'surveyBy',
    'classification',
    'notes',
    'notes.createdBy',
    'finance'
  ];

  async function getManyIndustry({
    filter = {},
    options,
    search
  }: GetManyQueries) {
    let query = useQueryBuilder({
      options,
      search,
      relations: industryRelations
    });
    const filterQuery = useIndustryFilterToQuery(filter);
    query += filterQuery;

    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/industries${query}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.industries = result.industries;
    state.total = result.count;

    return result;
  }

  async function getOneIndustry(id: string) {
    const params = `?relations=${industryRelations.join(',')}`;
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/industries/${id + params}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.industry = result.industry;

    return result;
  }

  async function postIndustry(data: any) {
    data = await useIndustryDto(data);

    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: `/industries`,
      data: unref(data)
    });

    if (errorMessage) return useErrorMessageHandler(error);

    return result;
  }

  async function putIndustry(data: any) {
    data = await useIndustryDto(data);

    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: `/industries/${data.id}`,
      data: unref(data)
    });

    if (errorMessage) return useErrorMessageHandler(error);

    return result;
  }

  async function deleteIndustry(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: `/industries/${data.id}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    return result;
  }

  async function verifyIndustry(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: `/industries/${data.id}/verify`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.industry = result.industry;

    return result;
  }

  async function postPermission(data: any) {
    data = toFormData(data);

    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: `/permissions`,
      data: unref(data)
    });

    if (errorMessage) return useErrorMessageHandler(error);

    return result;
  }

  async function deletePermission(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: `/permissions/${data.id}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    return result;
  }

  async function importIndustry(data: any) {
    // return { errorMessage: 'a', result: null, error: null };
    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: '/industries/import',
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return { errorMessage, result, error };
    }

    return { errorMessage, result, error };
  }

  async function getStatisticIndustry({
    filter = {},
    options,
    search
  }: GetManyQueries) {
    let query = useQueryBuilder({
      options,
      search,
      relations: industryRelations
    });
    const filterQuery = useIndustryFilterToQuery(filter);
    query += filterQuery;

    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/industries/statistics${query}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.statistics = result;

    return result;
  }

  async function getManyIndustryGeolocation({
    filter = {},
    options,
    search
  }: GetManyQueries) {
    let query = useQueryBuilder({
      options,
      search,
      relations: industryRelations
    });
    const filterQuery = useIndustryFilterToQuery(filter);
    query += filterQuery;

    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/industries/geolocation${query}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.geolocations = result.industries;

    return result;
  }

  return {
    ...toRefs(state),
    getManyIndustry,
    postIndustry,
    deleteIndustry,
    putIndustry,
    getOneIndustry,
    verifyIndustry,
    postPermission,
    deletePermission,
    importIndustry,
    getStatisticIndustry,
    getManyIndustryGeolocation
  };
});
