import VueRouter from "vue-router"

export default function useGoBack(router: VueRouter) {
    let pathArray = router.currentRoute.path.split('/')
    if (pathArray.length > 2) {
        
        pathArray.pop()
        let newPath = pathArray.join('/')

        // if (!newPath) newPath = '/'
        // console.log('newPath:', newPath);

        router.push(newPath)
    }

    // let newPath = '/anang-galuh'    
    // if (router.currentRoute.path === '/anang-galuh') newPath = '/'
    // else newPath = '/anang-galuh'
    // router.push(newPath)
    
}

export {
    useGoBack
}
