




























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { useGoBack } from '@/@core/helpers/useGoBack'
import { isMobile } from '@/@core/helpers/useBreakpoint'

export default defineComponent({
    name: 'componentName',
    props: {
        'text': {
            type: String as PropType<string>,
        },
        'icon': {
            type: String as PropType<string>,
            default: 'mdi-arrow-left'
        },
        'large': {
            type: Boolean as PropType<Boolean>,
            default: false
        },
        'color': {
            type: String as PropType<string>,
            default: ''
        },
        'mobile-only': {
            type: Boolean as PropType<boolean>,
            default: false
        },
    },
    setup(props) {
        // @ts-ignore
        const showButton = computed(() => props.mobileOnly ? isMobile.value : true)

        return {
            useGoBack,
            showButton
        }
    }
})
