




























import { isMobile } from '@/@core/helpers/useBreakpoint';
import { computed, defineComponent } from '@vue/composition-api';
import KBreadcrumbs from '../navigation/KBreadcrumbs.vue';
import KCard from '../other/KCard.vue';
import KWrapper from '../other/KWrapper.vue';

export default defineComponent({
  components: { KCard, KBreadcrumbs, KWrapper },
  name: 'KPageBody',
  props: {
    fillHeight: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    'hide-breadcrumb': {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const fullHeight = computed(() => {
      // const headerHeight = isMobile ? '65px' : '57px'
      const headerHeight = 0;

      return `
                min-height: 100vh;
                margin-top: -${headerHeight};
            `;
    });

    return {
      isMobile,
      fullHeight
    };
  }
});
